<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <c-btn
          text
          class="mb-2"
          :label="$t('main.back')"
          :icon-props="{
            icon: 'mdi-chevron-left'
          }"
          @click="$router.push({ name: routeNames.RTB_NETWORK })"
        />
        <v-card elevation="0">
          <v-progress-linear :active="loading" indeterminate color="primary" />
          <v-card-title class="black white--text mb-4">
            {{ $t('rtb.create') }}
          </v-card-title>
          <v-card-text>
            <div>
              {{ $t('rtb.ad_formats') }}
            </div>

            <v-chip-group
              v-model="selectedAdFormats"
              multiple
              column
              @change="errors.adFormats = []"
            >
              <v-chip v-for="format in clientAdFormats" :key="format" :value="format" filter outlined>
                {{ format }}
              </v-chip>
            </v-chip-group>

            <v-input v-if="errors.adFormats" :error-messages="errors.adFormats" />

            <v-row>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="platform"
                  :error-messages="errors.platform"
                  outlined
                  class="mt-2"
                  counter="100"
                  :label="$t('rtb.label_solutions')"
                  @focus="errors.platform = []"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="serverLocation"
                  :error-messages="errors.serverLocation"
                  outlined
                  class="mt-2"
                  counter="100"
                  :label="$t('rtb.server_location')"
                  @focus="errors.serverLocation = []"
                />
              </v-col>
            </v-row>

            <div class="mb-3">
              {{ $t('rtb.integration') }}
            </div>
            <v-radio-group v-model="openRtb" :error-messages="errors.openRtb" row>
              <v-radio :label="$t('main.yes')" :value="true" />
              <v-radio :label="$t('main.no')" :value="false" />
            </v-radio-group>

            <div class="mb-3">
              {{ $t('rtb.xml_integration') }}
            </div>
            <v-radio-group v-model="xml" :error-messages="errors.xml" row>
              <v-radio :label="$t('main.yes')" :value="true" />
              <v-radio :label="$t('main.no')" :value="false" />
            </v-radio-group>

            <div class="mb-3">
              {{ $t('rtb.ipv6_support') }}
            </div>
            <v-radio-group v-model="ipv6Support" :error-messages="errors.ipv6Support" row>
              <v-radio :label="$t('main.yes')" :value="true" />
              <v-radio :label="$t('main.no')" :value="false" />
            </v-radio-group>

            <div class="mb-3">
              {{ $t('rtb.dsp.label') }}
            </div>
            <v-radio-group v-model="dsp" :error-messages="errors.ipv6Support" row>
              <v-radio :label="$t('rtb.dsp.in_house')" :value="true" />
              <v-radio :label="$t('rtb.dsp.3rd_party_platform')" :value="false" />
            </v-radio-group>
          </v-card-text>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="websiteLink"
              type="url"
              :label="$t('rtb.website_link')"
              outlined
              required
              :error-messages="errors.websiteLink"
              @focus="errors.websiteLink = []"
            />
          </v-col>

          <v-card-actions>
            <v-spacer />
            <c-btn text :label="$t('rtb.cancel')" @click="$router.push({ name: routeNames.RTB_NETWORK })" />
            <c-btn
              color="primary"
              :disabled="loading"
              :label="$t('rtb.create_btn')"
              @click="submit()"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import ValidationError from '@/services/classes/validation-error.js'
  import { networksRepository } from '@/services/repository-factory.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'RtbCreateNetwork',
    components: {
      CBtn
    },
    data() {
      return {
        routeNames,
        errors: {},
        loading: false,
        selectedAdFormats: [],
        platform: '',
        serverLocation: '',
        openRtb: false,
        xml: false,
        ipv6Support: false,
        dsp: false,
        websiteLink: ''
      }
    },
    computed: {
      ...mapState('settings', ['clientAdFormats'])
    },
    methods: {
      submit() {
        this.loading = true
        networksRepository
          .store({
            adFormats: this.selectedAdFormats,
            platform: this.platform,
            serverLocation: this.serverLocation,
            openRtb: this.openRtb,
            xml: this.xml,
            ipv6Support: this.ipv6Support,
            dsp: this.dsp,
            websiteLink: this.websiteLink
          })
          .then(() => {
            gtmPush({ event: GTM_EVENTS.CREATE_RTB_NETWORK })
            this.$router.push({ name: routeNames.RTB_NETWORK })
          })
          .catch((error) => {
            if (error instanceof ValidationError) {
              this.errors = error.messages
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
